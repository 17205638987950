import { AppContextStore, KEY as AppContextKey } from 'theme/@types/zento/stores/applicationContext';
import type { IProductState } from 'theme/@types/vsf/stores/product';
import { Component, BaseComponent, Prop, namespace } from '@zento-lib/components';
import { formatProductLink } from '@vue-storefront/core/modules/url/helpers';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';

import { ProductPrice } from '../../../atom/Product/Price/Price';
import { Link } from '../../../atom/Link/Link';
import { MainImage } from '../../../atom/MainImage/MainImage';
import { InnerHTML } from '../../../atom/InnerHTML';

import type { ISearchProducts } from './SearchProducts.d';
import style from './style.scss';

const appContextStore = namespace<AppContextStore>(AppContextKey);

/**
 * Search Product
 *
 * Search Product component allowing product info detailed.
 **/
@Component({})
export class SearchProducts extends BaseComponent<ISearchProducts, unknown> {
  private static T = {
    productPriceInclLabel: 'product_price_incl_label',
    productPriceExclLabel: 'product_price_excl_label',
  };

  /**
   * Determines object of product
   */
  @Prop({ type: Object, required: true })
  product: IProductState | any;

  /**
   * Determines if product has image
   */
  @Prop({ type: Boolean, default: false })
  onlyImage?: boolean;

  /**
   * Determines product position in grid/carousel
   */
  @Prop({ type: Number, default: 0 })
  position?: number;

  @appContextStore.Getter('isServer')
  isServer: boolean;

  /**
   * Determines selected swatch image for main image box
   */
  productThumbnailPath(product: IProductState) {
    let thumbnail =
      product.thumbnail && product.thumbnail !== 'no_selection'
        ? product.thumbnail
        : product.image && product.image !== 'no_selection'
        ? product.image
        : '';

    if (
      product.type_id &&
      product.type_id === 'configurable' &&
      'configurable_children' in product &&
      product.configurable_children.length &&
      !product.is_configured &&
      'image' in product.configurable_children[0]
    ) {
      thumbnail = product.configurable_children[0].image;
      if (!thumbnail || thumbnail === 'no_selection') {
        const childWithImg = product.configurable_children.find((f) => f.image && f.image !== 'no_selection');
        if (childWithImg) {
          thumbnail = childWithImg.image;
        } else {
          thumbnail =
            product.thumbnail && product.thumbnail !== 'no_selection'
              ? product.thumbnail
              : product.image && product.image !== 'no_selection'
              ? product.image
              : '';
        }
      }
    }

    return {
      src: thumbnail,
      loading: thumbnail,
    };
  }

  /**
   * Get product link
   */
  get productLink() {
    return formatProductLink(this.product, currentStoreView().storeCode);
  }

  get imageSizes() {
    return this.extended.$config.zento.images.productsThumbnails;
  }

  render() {
    const config = this.extended.$config.zento.theme;

    return (
      <li
        onClick={() => {
          if (!this.isServer && this.product) {
            this.broadcast('analytics/product-click-track', {
              product: this.product,
              position: this.position + 1,
              list: 'Search Results',
            });
            this.broadcast('analytics/product-click-track-custom', {
              product: this.product,
              position: this.position + 1,
              list: 'Search Results',
            });
          }
        }}
        class={style.item}>
        <Link to={this.productLink} data-testId='productLink' class={style.overlayLink} key='product-link-name' />
        <div class={style.itemInner}>
          <div class={style.productBox} key='product-box'>
            <div class={style.productImage} key='product-image'>
              <span key='track-event'>
                <Link to={this.productLink} data-testId='productLink' key='product-link'>
                  <MainImage
                    image={this.productThumbnailPath(this.product)}
                    alt={this.product.name}
                    width={this.imageSizes.width}
                    height={this.imageSizes.height}
                    tabletWidth={this.imageSizes.tabletWidth}
                    tabletHeight={this.imageSizes.tabletHeight}
                    desktopWidth={this.imageSizes.desktopWidth}
                    desktopHeight={this.imageSizes.desktopHeight}
                    key='main-image'
                  />
                </Link>
              </span>
            </div>
            <div class={style.itemContent} key='item-content'>
              {!this.onlyImage ? (
                <h2 class={style.productTitle} key='product-name'>
                  <InnerHTML class={style.productLink} contents={this.product.name} key='product-name-content' />
                </h2>
              ) : null}

              <ProductPrice
                product={this.product}
                onlyImagePrice={this.onlyImage}
                productPrice={this.product}
                qtyIncrement={true}
                showPriceDiscount={config.price.showDiscountPercentage}
                productPriceInclLabel={{ id: SearchProducts.T.productPriceInclLabel }}
                productPriceExclLabel={{ id: SearchProducts.T.productPriceExclLabel }}
                key='product-prices'
                class={style.priceWrapperBox}
              />
            </div>
          </div>
        </div>
      </li>
    );
  }
}
